import * as React from 'react';
import { ItemTemplateProvider } from '../templates/itemtemplateprovider';
import { getBlocTemplate, getItemsProperties } from '../../services/itemshelpers';
import { EntityTemplateContextProvider } from '../entities/entitytemplatecontext';
import { TeaserItemsCarousel } from './teaseritems.carousel';
import type { ITeaserItemsProps } from './teaseritems.props';
import { TeaserItemsList } from './teaseritems.list';
import { TeaserItemsTimeline } from './teaseritems.timeline';
import type { States } from '@@services/services';

import './teaseritems.less';

export type { ITeaserItemsProps, ITeaserItemsPartProps } from './teaseritems.props';

export class TeaserItems extends React.Component<ITeaserItemsProps, any> {
    constructor(props: ITeaserItemsProps) {
        super(props);
        this.state = getItemsProperties(props.template.id, props.visualstate, props.itemproperties || props.template.properties,
            props.itemtemplate, props.defaultItemsAlign, props.defaultItemsLayout);
    }

    componentDidUpdate(prevProps: ITeaserItemsProps) {
        const isRootWebsite = this.props.rootwebsite?.detail;
        if ((this.props.visualstate !== prevProps.visualstate)
            || ((this.props.template && this.props.template.properties)
            !== (prevProps.template && prevProps.template.properties))) {
            this.setState(getItemsProperties(this.props.template.id, this.props.visualstate, this.props.template.properties,
                this.props.itemtemplate, this.props.defaultItemsAlign, this.props.defaultItemsLayout));
        }

        if (InWinkPreview && this.props.update) {
            let data: States.IDataStoreWithTemplates;
            if (isRootWebsite && prevProps.rootwebsite !== this.props.rootwebsite) {
                data = this.props.rootwebsite?.data;
            } else if (prevProps.community !== this.props.community) {
                data = this.props.community?.data;
            } else if (prevProps.event !== this.props.event) {
                data = this.props.event?.data;
            }

            if (data) {
                this.props.update({ itemtemplate: getBlocTemplate(data, this.props.template) });
            }
        }
    }

    getTemplate(templatename: string) {
        if (this.props.itemtemplate && this.props.itemtemplate[templatename]) {
            return this.props.itemtemplate[templatename];
        }
    }

    getTemplateProperties() {
        return this.props.templateProperties;
    }

    render() {
        if (!this.props.allowEmpty && (!this.props.items || !this.props.items.length)) {
            return <div className="empty" />;
        }

        const context = {
            ...this.props.datacontext,
            templateProperties: this.props.template.properties,
            templateOptions: this.props.itemtemplate && (this.props.itemtemplate as any).templateOptions
        };

        if (this.props.template.properties && this.props.template.properties.itemsDisplayMode === "carousel"
            && this.props.items && this.props.items.length > (this.props.minCarouselItems || 5)) {
            return <EntityTemplateContextProvider fieldTemplate={this.props.entitySchema}>
                <ItemTemplateProvider provider={this}>
                    <TeaserItemsCarousel
                        {...this.props}
                        datacontext={context}
                        itemsAlign={this.state.itemsAlign}
                        itemsLayout={this.state.itemsLayout}
                    />
                </ItemTemplateProvider>
            </EntityTemplateContextProvider>;
        } if (this.props.template.properties && this.props.template.properties.itemsDisplayMode === "timeline") {
            return <EntityTemplateContextProvider fieldTemplate={this.props.entitySchema}>
                <ItemTemplateProvider provider={this}>
                    <TeaserItemsTimeline
                        {...this.props}
                        datacontext={context}
                        itemsAlign={this.state.itemsAlign}
                        itemsLayout={this.state.itemsLayout}
                    />
                </ItemTemplateProvider>
            </EntityTemplateContextProvider>;
        }
        return <EntityTemplateContextProvider fieldTemplate={this.props.entitySchema}>
            <ItemTemplateProvider provider={this}>
                <TeaserItemsList
                    {...this.props}
                    datacontext={context}
                    itemsAlign={this.state.itemsAlign}
                    itemsLayout={this.state.itemsLayout}
                    itemsAlignCSS={this.state.itemsAlignCSS}
                    itemsLayoutCSS={this.state.itemsLayoutCSS}
                    itemsLayoutClassName={this.state.itemsLayoutClassName}
                />
            </ItemTemplateProvider>
        </EntityTemplateContextProvider>;
    }
}
