import * as React from 'react';
import { Entities } from '@inwink/entities/entities';

export interface IEntityTemplateContext{
    parent?: IEntityTemplateContext;
    fieldTemplate? : Entities.IFieldTemplate;
}

const TMPEntityTemplateContext = React.createContext<IEntityTemplateContext>(null);

export interface IEntityTemplateContextProviderProps {
    fieldTemplate: Entities.IFieldTemplate;
    children?: React.ReactNode;
}

export function EntityTemplateContextProvider(props: IEntityTemplateContextProviderProps) {
    return <TMPEntityTemplateContext.Consumer>
        {(tpl) => <EntityTemplateContextProviderContent
            parent={tpl}
            fieldTemplate={props.fieldTemplate}
        >{props.children}</EntityTemplateContextProviderContent>}
    </TMPEntityTemplateContext.Consumer>;
}

export interface IEntityTemplateContextConsumerProps {
    children: (context: IEntityTemplateContext) => React.ReactNode;
}

export function EntityTemplateContextConsumer(props: IEntityTemplateContextConsumerProps) {
    return <TMPEntityTemplateContext.Consumer>
        {(tpl) => props.children(tpl)}
    </TMPEntityTemplateContext.Consumer>;
}

interface IEntityTemplateContextProviderContentProps {
    parent: IEntityTemplateContext;
    fieldTemplate: Entities.IFieldTemplate;
    children?: React.ReactNode;
}
interface IEntityTemplateContextProviderContentState {
    context: IEntityTemplateContext;
}

class EntityTemplateContextProviderContent
    extends React.Component<IEntityTemplateContextProviderContentProps, IEntityTemplateContextProviderContentState> {
    constructor(props: IEntityTemplateContextProviderContentProps) {
        super(props);

        let ctx: IEntityTemplateContext;
        if (props.fieldTemplate) {
            ctx = {
                parent: props.parent,
                fieldTemplate: props.fieldTemplate
            };
        } else if (props.parent) {
            ctx = props.parent;
        }

        this.state = {
            context: ctx
        };
    }

    componentDidUpdate(prevprops: IEntityTemplateContextProviderContentProps) {
        if (prevprops.parent !== this.props.parent || prevprops.fieldTemplate !== this.props.fieldTemplate) {
            let ctx: IEntityTemplateContext;
            if (this.props.fieldTemplate) {
                ctx = {
                    parent: this.props.parent,
                    fieldTemplate: this.props.fieldTemplate
                };
            } else if (this.props.parent) {
                ctx = this.props.parent;
            }

            this.setState({
                context: ctx
            });
        }
    }

    render() {
        return <TMPEntityTemplateContext.Provider value={this.state.context}>
            {this.props.children}
        </TMPEntityTemplateContext.Provider>;
    }
}
