import * as React from 'react';
import { DynLabel, AppTextLabel } from '../../services/i18nservice';
import { DynamicBloc } from './common';
import { BlocTitle } from "./common.title";
import { ContentStyle } from '../contentstyle';
import { ItemTemplate } from '../templates/itemtemplate';
import type { ITeaserItemsPartProps } from './teaseritems.props';

export function TeaserItemsTimeline(props: ITeaserItemsPartProps) {
    let itemsComponents;
    let itemstyle;
    const itemsLayout = props.itemsLayout || "ukn";
    const itemsAlign = props.itemsAlign || "ukn";

    if (props.items && props.items.length) {
        const items = props.items;

        if (props.itemtemplate || props.onGetItem) {
            let template = props.itemtemplate as any;
            if (template && template.template) { // dans ce cas on est sur un IItemTemplateConfig
                if (template.customCSS) {
                    itemstyle = <ContentStyle
                        css={template.customCSS}
                        blocid={props.bloctemplate.id}
                        contentid={props.template.id}
                        theme={props.theme}
                    />;
                }

                template = template.template;
            }
            const editable = props.editable && props.templateEditable;
            itemsComponents = items.filter((s) => !!s).map((s, idx) => {
                if (props.onGetItem) {
                    return <div
                        className="inwink-item"
                        key={s.id + "#" + idx}
                        id={('item-' + s.id) || ('#' + idx)}
                    >{props.onGetItem(props, s)}</div>;
                }
                return <div className="inwink-item" key={s.id + "#" + idx} id={('item-' + s.id) || ('#' + idx)}>
                    <ItemTemplate
                        template={template}
                        contentEditable={editable}
                        theme={props.theme}
                        page={props.page}
                        user={props.user.currentUser}
                        i18n={props.i18n}
                        data={s}
                        link={s && s.$link}
                        datacontext={props.datacontext}
                        className={props.itemClassName}
                        onClick={props.onItemClick}
                        linkto={props.linkto}
                        urlservice={props.urlservice}
                    />
                </div>;
            });
        }
    } else if (props.emptyComponent) {
        itemsComponents = props.emptyComponent;
    } else if (props.emptyMessage) {
        if (typeof props.emptyMessage === "string") {
            itemsComponents = <div key="message" className="message"><AppTextLabel i18n={props.emptyMessage} /></div>;
        } else {
            itemsComponents = <div key="message" className="message"><DynLabel i18n={props.emptyMessage} /></div>;
        }
    }

    let itemsStyle = null;

    if (props.template && props.template.properties && props.template.properties.listItemsAlign) {
        itemsStyle = { justifyContent: props.template.properties.listItemsAlign };
    }

    return <DynamicBloc
        {...props}
        className={"itemstimeline " + (props.className || "") + ((props.template.properties?.className) || "")}
    >
        {itemstyle}
        <BlocTitle {...props} className="bloc-header" />
        <div className={"inwink-items bloc-content layout-" + itemsLayout + " align-" + itemsAlign} style={itemsStyle}>
            {itemsComponents}
        </div>
    </DynamicBloc>;
}
