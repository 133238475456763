import * as React from 'react';

export interface IItemTemplateProvider {
    getTemplate :(templatename : string) => any;
    getTemplateProperties() : any;
}

export const ItemTemplateProviderContext = React.createContext<IItemTemplateProvider>(null);

export interface IItemTemplateProviderProps {
    provider: IItemTemplateProvider;
    children: any;
}

export function ItemTemplateProvider(props: IItemTemplateProviderProps) {
    return <ItemTemplateProviderContext.Provider value={props.provider}>
        {(props as any).children}
    </ItemTemplateProviderContext.Provider>;
}

export function withItemTemplateProvider() {
    return (target) => {
        // eslint-disable-next-line react/display-name
        return React.forwardRef((props, ref) => <ItemTemplateProviderContext.Consumer>
            {(templateprovider) => React.createElement(target, { ...props, itemtemplateprovider: templateprovider, ref: ref })}
        </ItemTemplateProviderContext.Consumer>) as typeof target;
    };
}
